import React from "react"
import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <Content>
        <h1>About me</h1>
        <p>
            I'm David Caballero, love to drink some good coffee, no sugar, movies and a good book.
        </p>
        <h2>What i do for a living?</h2>
        <p>Some DevOps stuff, Webs, Automations, and IaC.</p>
        <h2>Experience</h2>
        <p>Take a look to my <Link to="https://www.linkedin.com/in/dcaballerom/" target="_blank" >Linkedin</Link></p>
        <h2>Achievements</h2>
        <h4>Based on important things in life...</h4>
        <p>Learning wood carving.</p>
        <h4>Based on DevOps...</h4>
        <p>
            US: DevOps, as Contractor
            DevOps Associate for AWS Services at the company, Accomplished as a teamwork SLA of 99.99% over all the platforms of the company, Experienced delivering changes with GitHub, Ansible, and AWS, Production support for Kubernetes Clusters and Docker Hosts. Over 2000 Containers and Pods, with several different products.
        </p>
        <p>
            Canada: Analyst, and IT Support, e-Consilium & Loblaws inc.
            Upgrades and Better performances in different landscapes, Support of ORMS Oracle Retail Merchandising System, Support of WebPDM Web Product development and management ,Support of TMOD Transportation Modeler, Support and Deployment of JDA i2 TMS
        </p>
        <h2>Some interesting repos</h2>
        <p>
        <Link to="https://registry.terraform.io/modules/arkhoss/eks-roles/aws/1.0.0" target="_blank" >Terraform Registry - TF AWS eks-roles module</Link><br></br>
        <Link to="https://gitlab.com/arkhoss/cd-aws-cloudformation-ecs" target="_blank" >AWS - CI/CD in AWS CloudFormation and ECS demo with Fargate</Link><br></br>
        <Link to="https://gitlab.com/arkhoss/kube-cluster-vagrant" target="_blank" >Kube cluster with Vagrant and Ansible</Link><br></br>
        <Link to="https://gitlab.com/arkhoss/elk-lab" target="_blank" >Full ELK deployment</Link><br></br>
        </p>
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`
